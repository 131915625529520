<template>
  <tr
    v-if="!props.item.deleted"
    @click="!isSelecting ? props.expand(!props.isExpanded):null; isSelecting=false "
    :class="props.item.init && props.item.recordtype!='kitcomponent' ? 'kitcomponents' : ''"
   
  >
    <td v-if="props && props.headers && props.headers[0] && props.headers[0].value=='data-table-select'">
      <v-checkbox class="mt-0" :input-value="props.isSelected" @click.stop="props.select(!props.isSelected)" primary hide-details></v-checkbox>
    </td>
    <v-lazy tag="td" v-for="col in headers.filter(f=>!f.parentfield)" :key="col.value" :id="col.value" :field="col.field" :value="lazy" @mousedown="startSelection" @mouseup="checkSelection" >
      <span>
        <span v-if="col.value">
          <router-link
            v-if="fields[col.field].setup.type == 'field-select' && props.item[col.value] && props.item[col.value]._id && fields[col.field].setup.link!=false"
            :to="{path: `/${props.item[col.value].collection}/${props.item[col.value].recordtype._id || props.item[col.value].recordtype}/view/${props.item[col.value]._id}`}"
            class="link"
          >{{ props.item[col.value].displayname || props.item[col.value].name }}</router-link>

          <router-link
            v-if="fields[col.field].setup.type =='field-autocomplete' && props.item[col.value] && props.item[col.value]._id && fields[col.field].setup.link!=false"
            :to="{path: `/${props.item[col.value].collection}/${props.item[col.value].recordtype._id || props.item[col.value].recordtype}/view/${props.item[col.value]._id}`}"
            class="link"
          >{{ props.item[col.value].displayname || props.item[col.value].name }}</router-link>

          <span v-if="fields[col.field].setup.type =='field-file' && props.item[col.value] && fields[col.field].setup.link!=false">
            <a
              v-if="props.item[col.value]._id"
              :href="`${props.item[col.value].url}`"
              class="link"
            >{{ props.item[col.value].displayname || props.item[col.value].name }}</a>
            <a v-else v-for="item in props.item[col.value]" :key="item._id" class="link" :href="`${item.url}`">
              {{ item.displayname || item.name }}
            </a>
          </span>
          <span class="d-block"
            v-if="(fields[col.field].setup.type =='field-autocomplete' || fields[col.field].setup.type == 'field-select') && props.item[col.value] && props.item[col.value]._id && fields[col.field].setup.link==false && !props.item[col.value].color"
          >{{ props.item[col.value] ? props.item[col.value].name : "" }}</span>
          <v-chip label dark :color="props.item[col.value].color"
            v-if="(fields[col.field].setup.type =='field-autocomplete' || fields[col.field].setup.type == 'field-select') && props.item[col.value] && props.item[col.value]._id && fields[col.field].setup.link==false && props.item[col.value].color"
          >{{ props.item[col.value] ? props.item[col.value].name : "" }}</v-chip>



          <span
            v-if="(fields[col.field].setup.type =='field-autocomplete' || fields[col.field].setup.type == 'field-select') && !fields[col.field].setup.array && props.item[col.value] && !props.item[col.value]._id"
          >{{ props.item[col.value] }}</span>

          <span v-if="fields[col.field].setup.array && fields[col.field].setup.link!=false && fields[col.field].setup.collection && fields[col.field].setup.type !='field-file' && Array.isArray(props.item[col.value])">
            <router-link
              v-for="item in props.item[col.value]"
              :key="item._id"
              :to="{path: `/${item.collection}/${item.recordtype._id || item.recordtype}/view/${item._id}`}"
              class="link d-block"
            >{{ item.displayname || item.name }}</router-link>
          </span>

          <span v-if="fields[col.field].setup.array && fields[col.field].setup.link==false && props.item[col.value] && Array.isArray(props.item[col.value])">
            <span class="d-block" v-for="item in props.item[col.value].filter(v=>!v.deleted)" :key="item._id">{{ item.displayname || item.name }}</span>
          </span>

          <span
            v-if="fields[col.field].setup.type == 'field-date' && !col.function"
          >{{props.item[col.value] ? new Date(props.item[col.value]).toISOString().substr(0, 10):null}}</span>

          <span
            v-if="fields[col.field].setup.type == 'field-date' && col.function"
          >{{props.item[col.value]}}</span>

          <span
            v-if="fields[col.field].setup.type == 'field-currency'"
          >{{props.item[col.value] ? Number(props.item[col.value]).toFixed(fields[col.field].setup.precision || 2):null}}</span>

          <span v-if="fields[col.field].setup.type == 'field-combobox'" >{{props.item[col.value]}}</span>
          <component :is="fields[col.field].setup.pre ? 'pre':'span'" v-if="fields[col.field].setup.type == 'field-text'">{{props.item[col.value]}}</component>
          <component :is="fields[col.field].setup.pre ? 'pre':'span'" v-if="fields[col.field].setup.type == 'field-textarea'">{{props.item[col.value]}}</component>
          <span v-if="fields[col.field].setup.type == 'field-country'">{{props.item[col.value]}}</span>
          <span v-if="fields[col.field].setup.type == 'field-email'">{{props.item[col.value]}}</span>
          <span v-if="fields[col.field].setup.type == 'field-int' && (!['quantitycommitted','quantitybackorder'].includes(col.value) || props.item[col.value]==undefined)">{{props.item[col.value]}}</span>
          <span v-if="fields[col.field].setup.type == 'field-int' && col.value=='quantitycommitted' && props.item[col.value]!=undefined">
             <v-chip
              label
              :color="props.item.quantitytopack ? 'yellow': props.item[col.value]?'green':''"
              dark
            >{{props.item[col.value]}}</v-chip>
          </span>
          <span v-if="fields[col.field].setup.type == 'field-int' && col.value=='quantitybackorder' && props.item[col.value]!=undefined">
             <v-chip
              label
              :color="props.item[col.value]?'red':''"
              dark
            >{{props.item[col.value]}}</v-chip>
          </span>
          <span v-if="fields[col.field].setup.type == 'field-color'" :style="{color:props.item[col.value]}">{{props.item[col.value]}}</span>
          <span v-if="fields[col.field].setup.type == 'field-boolean'">         
            <v-chip
              label
              :color="props.item[col.value]?'green':'red'"
              dark
            >{{ props.item[col.value]?"Yes":"No" }}</v-chip>
          </span>
          <span
            v-if="fields[col.field].setup.type == 'field-percent'"
          >{{((props.item[col.value]||0)*100).toFixed(2)}}%</span>
        </span>
        <span v-else>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <router-link :to="link(props.item,'view')">
                <v-icon small class="mr-2" @click="viewRecord(props.item);" v-on="on">launch</v-icon>
              </router-link>
            </template>
            <span>View</span>
          </v-tooltip>|
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <router-link :to="link(props.item,'edit')">
                <v-icon small @click="editRecord(props.item);" v-on="on">edit</v-icon>
              </router-link>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </span>
        <v-tooltip bottom color="error" v-if="props.item.fieldmessage && props.item.fieldmessage[col.field]">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" color="error" v-bind="attrs" v-on="on">warning</v-icon>
          </template>
          <span>{{props.item.fieldmessage[col.field]}}</span>
        </v-tooltip>
        <span v-if="col.subfield && props.item[col.subfield.field] && (Array.isArray(props.item[col.subfield.field]) ? !!props.item[col.subfield.field].length : true)">
          <v-tooltip bottom color="error" v-if="col.subfield.type=='warning'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" color="error" v-bind="attrs" v-on="on">warning</v-icon>
            </template>
            <span>{{col.subfield.name}} {{props.item[col.subfield.field]}}</span>
          </v-tooltip>
          <component class="d-block" :is="col.subfield.type=='pre' ? 'pre':'small'" v-else>
            {{col.subfield.name}}
            <span v-if="Array.isArray(props.item[col.subfield.field])">
            <router-link
              v-for="item in props.item[col.subfield.field]"
              :key="item._id"
              :to="{path: `/${item.collection}/${item.recordtype._id || item.recordtype}/view/${item._id}`}"
              class="link d-block"
            >{{ item.displayname || item.name }}</router-link>
          </span>
          <span v-else>
            {{props.item[col.subfield.field].name || props.item[col.subfield.field]}}
          </span>
          </component>
        </span>
      </span>
    </v-lazy>
  </tr>
</template>
<script>
/* eslint-disable */
import { getName } from "country-list";
export default {
  props: ["props", "selected", "headers", "options", "fields","lazy"],
  data() {
    return {
      isSelecting:false
    };
  },
  created() {
    //console.log(this.props);
  },
  computed: {
    valueCountry(value) {
      //console.log(value);
      if (value) return getName(value);
      else return "";
    },
  },
  methods: {
  hasAccess(collection, recordtype, action) {
      if (
        collection &&
        action &&
        this.$store.state.user.role &&
        this.$store.state.user.role.permission
      ) {
   
        if (this.$store.state.user.role.permission[collection]) {
          if (this.$store.state.user.role.permission[collection][recordtype]) {
            if (
              this.$store.state.user.role.permission[collection][recordtype] &&
              Array.isArray(this.$store.state.user.role.permission[collection][recordtype])
            ){
              return this.$store.state.user.role.permission[collection][
                recordtype
              ].includes(action);
            }
            
          } else {
            if (this.$store.state.user.role.permission[collection] && Array.isArray(this.$store.state.user.role.permission[collection]))
              return this.$store.state.user.role.permission[
                collection
              ].includes(action);
          }
        } else return true;
      }
      if (
        this.$store.state.user.location &&
        !this.$store.state.user.viewonly &&
        (this.record && this.record.salesrep
          ? (this.record.salesrep._id || this.record.salesrep) !=
            this.$store.state.user._id
          : false)
      ) {
        let availablelocations = [
          undefined,
          null,
          false,
          this.$store.state.user.location,
          ...(this.$store.state.user.forlocations || []),
        ];
        return availablelocations.includes(
          this.record && this.record.location
            ? this.record.location._id || this.record.location
            : undefined
        );
      } else return true;
    },
    startSelection() {
      this.isSelecting = false; // Zakładamy, że na początku nie ma zaznaczenia
    },
    checkSelection() {
      const selectedText = window.getSelection().toString();
      if (selectedText.length > 0) {
        this.isSelecting = true; // Ustawiamy, że zaznaczenie miało miejsce
      }
    },
    link(record, mode) {
      if(record && record.collection && this.hasAccess(record.collection, record.recordtype, mode)){
      return `/${record.collection}/${
        record.recordtype._id || record.recordtype
      }/${mode}/${record._id}`;
    } else return record;
    },
    viewRecord(record) {
      this.$router.push({
        path: `/${record.collection}/${
          record.recordtype._id || record.recordtype
        }/view/${record._id}`,
      });
    },
    editRecord(record) {
    if(this.hasAccess(record.collection, record.recordtype, 'edit')){
      this.$router.push({
          path: `/${record.collection}/${
            record.recordtype._id || record.recordtype
          }/edit/${record._id}`,
        });
    }else{
      alert("Access Danied")
    }
     
    },
  },
};
</script>

<style scoped>
.kitcomponents {
  background: #ececec;
}
</style>
