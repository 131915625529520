<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <fiscalPrinter
          :record="record"
          v-if="
            $route.params.mode == 'view' &&
            record.company._id == 'brakespl' &&
            record.customtype &&
            record.customtype._id == '5e0dbaba9e33df43f0b3a4e4' &&
            !prepayments &&
            !assignedprepayment
          "
        />
        <v-alert
          type="warning"
          dense
          v-for="instruction of record.customer
            ? record.customer.additionalinstructions
            : []"
          :key="instruction._id"
          >{{ instructions[instruction._id || instruction] }}</v-alert
        >

        <v-alert
          v-if="record.weight > 150"
          type="warning"
          dense
          icon="mdi-weight-kilogram"
        >
          Over 150Kg - individual quotation on freight cost
        </v-alert>

        <v-alert
          type="info"
          dense
          v-if="record.customer && record.customer.shippinginstructions"
        >
          <pre>{{ record.customer.shippinginstructions }}</pre>
        </v-alert>
        <v-alert v-if="overdue" type="error" dense icon="mdi-fire">
          Overdue Invoice
        </v-alert>
        <v-alert v-if="noprofit" dense type="error" icon="mdi-trending-down">
          No Profit
        </v-alert>
        <v-alert
          type="error"
          dense
          v-if="
            record.validtaxnumber == false &&
            this.record.billcountry &&
            !this.record.billcountry.exportcustomsclearance &&
            this.record.customer &&
            !this.record.customer.isperson
          "
          >Invalid VAT Number</v-alert
        >
        <v-alert
          type="error"
          dense
          v-if="record.customer && record.customer.accountonhold"
          >Account On Hold!</v-alert
        >
        <v-alert
          type="success"
          dense
          v-if="
            openif.length &&
            record.location &&
            record.location._id != '61e14c6ae4a35a668517a8fe'
          "
          icon="mdi-package-up"
          >Packaging in progress:
          {{ openif.map((r) => r.name).join(", ") }}</v-alert
        >
        <v-alert type="error" dense v-if="!assignedprepayment && prepayments"
          >Please do not send invoice, prepayment available for assignment
        </v-alert>
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" style="max-width: 50%">
                      <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                      <v-list-item-title>
                        <router-link
                          v-if="record.customer"
                          :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{ record.customer.name }}</router-link
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="
                        record.salesrep ||
                        (record.customer && record.customer.salesrep)
                      "
                    >
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title
                        v-if="record.location._id == '62de6196fa2fc522dc692c7e'"
                      >
                        {{
                          record.salesrep
                            ? record.salesrep.name
                            : record.customer.salesrep
                            ? record.customer.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{
                          record.customer.salesrep
                            ? record.customer.salesrep.name
                            : record.salesrep
                            ? record.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">business</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title
                        >{{ billingaddress.company
                        }}{{ billingaddress.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ billingaddress.address }}<br />{{
                          billingaddress.country
                        }}</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">local_shipping</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title
                        >{{ shippingaddress.company
                        }}{{ shippingaddress.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ shippingaddress.address }}<br />{{
                          shippingaddress.country
                        }}</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (record.correlated && record.correlated.length)
                "
              ></v-divider>
              <v-list-item
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (record.correlated && record.correlated.length)
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created From:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="createdfrom of record.createdfrom"
                          :key="createdfrom._id"
                          :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                        >
                          {{ createdfrom.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="baselinker_archived.length">
                      <v-list-item-subtitle
                        >Baselinker Archived Order:</v-list-item-subtitle
                      >
                      <v-list-item-title
                        v-for="order of baselinker_archived"
                        :key="order"
                        >{{ order }}</v-list-item-title
                      >
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Correlated WZ:</v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <router-link
                          v-for="correlated of record.correlated.reduce(
                            (total, wz) => {
                              if (!total.find((v) => v._id == wz._id))
                                total.push(wz);
                              return total;
                            },
                            []
                          )"
                          :key="correlated._id"
                          :to="`/transactions/${correlated.recordtype}/view/${correlated._id}`"
                        >
                          {{ correlated.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>

          <v-col>
            <v-subheader>Summary</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Net. Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ amount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Items Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ itemsamount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ taxamount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Shipping Cost:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        shippingamount
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        <b>{{ grossamount }}</b>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Paid:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ paid }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Due Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ amountdue }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Due Date:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ duedate }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Cost:</v-list-item-subtitle>
                      <v-list-item-title>{{ cost }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Profit:</v-list-item-subtitle>
                      <v-list-item-title>{{ profit }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.quantity
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Total Weight:</v-list-item-subtitle>
                      <v-list-item-title>{{ weight }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>

        <v-row v-if="prepayments">
          <v-col>
            <v-alert type="error">Available Prepayments </v-alert>
            <v-btn
              v-if="$route.params.mode != 'view'"
              color="primary"
              class="mt-2"
              @click="
                $emit('emit', {
                  activeTab: 1,
                  section: '#tool-assignprepayment',
                })
              "
              >Skip to Prepayments</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox
        :record="record"
        :customer="record.customer._id"
        filter="record"
      />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
import fiscalPrinter from "../../tools/fiscalprinter/View.vue";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
      prepayments: false,
      assignedprepayment: false,
      instructions: {},
      baselinker_archived: [],
      openif: [],
    };
  },
  watch: {
    assignedprepayment(v) {
      if (this.$route.params.mode == "view") {
        this.record.tmp_assignedprepayment = v;
      }
    },

    prepayments(v) {
      if (this.$route.params.mode == "view") {
        this.record.tmp_prepayments = v;
      }
    },
  },
  async created() {
    if (this.record.customer && this.record.customer.salesrep)
      this.record.customer.salesrep = await service("users", {
        action: "findOne",
        data: [{ _id: this.record.customer.salesrep }],
      });
    if (
      this.record.baselinker_order_id &&
      this.record.baselinker_order_id.length
    )
      for (let order of this.record.baselinker_order_id) {
        let status = await service("baselinker", {
          action: "baselinker_getStatus",
          order: order,
        });
        if (status == "Archived") this.baselinker_archived.push(order);
      }

    if (this.record.customer) {
      let prepayments = await service("transactions", {
        action: "getPrepayments",
        rid: (this.record.createdfrom || []).map((t) => t._id || t),
        customer: this.record.customer._id,
        company: this.record.company._id,
      });

      this.prepayments = prepayments && prepayments.items.length ? true : false;
      if (this.record.prepayments && this.record.prepayments.length) {
        let prepaymentamount = 0;
        this.record.prepayments.forEach((p) => {
          prepaymentamount += p.grossamount;
        });
        if (this.record.grossamount + parseFloat(prepaymentamount.toFixed(2)) <= 0)
          this.assignedprepayment = true;
      }
    }
    this.instructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "additionalinstruction" }],
    }).then((response) => {
      let ids = {};
      response.map((v) => {
        ids[v._id] = v.name;
      });
      return ids;
    });

    // this.openif = await this.getopenif();
  },
  async updated() {
    let prepayments = await service("transactions", {
      action: "getPrepayments",
      rid: (this.record.createdfrom || []).map((t) => t._id || t),
      customer: this.record.customer._id,
      company: this.record.company._id,
    });

    this.prepayments = prepayments && prepayments.items.length ? true : false;
    if (this.record.prepayments && this.record.prepayments.length) {
      let prepaymentamount = 0;
      this.record.prepayments.forEach((p) => {
        prepaymentamount += p.grossamount;
      });
      if (this.record.grossamount + parseFloat(prepaymentamount.toFixed(2)) <= 0)
        this.assignedprepayment = true;
    }
  },
  methods: {
    async getopenif() {
      if (this.record.customer) {
        return await service("transactions", {
          action: "find",
          data: [
            {
              recordtype: "itemfulfillment",
              status: { $nin: ["shipped", "packed"] },
              customer: this.record.customer._id,
            },
            {
              projection: {
                _id: true,
                name: true,
              },
            },
          ],
        });
      } else return [];
    },
    formatCurrency(value, currency = "PLN") {
      return new Intl.NumberFormat(currency !== "PLN" ? "en-EN" : "pl-PL", {
        style: "currency",
        currency: currency,
      }).format(value || 0);
    },
  },
  computed: {
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    customer() {
      //console.log("Customer", this.record.customer)
      return this.record.customer ? this.record.customer.name : "";
    },
    duedate() {
      return this.record.duedate
        ? new Date(this.record.duedate).toISOString().substr(0, 10)
        : null;
    },
    amount() {
      return this.formatCurrency(
        this.record.amount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    grossamount() {
      return this.formatCurrency(
        this.record.grossamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    taxamount() {
      return this.formatCurrency(
        this.record.taxamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    paid() {
      return this.formatCurrency(
        this.record.paid,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    amountdue() {
      return this.formatCurrency(
        this.record.amountdue,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    shippingamount() {
      return this.formatCurrency(
        this.record.shippingcost,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    itemsamount() {
      return this.formatCurrency(
        this.record.itemsamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    cost() {
      return this.formatCurrency(this.record.cost);
    },

    profit() {
      return this.formatCurrency(this.record.profit);
    },
    addresses() {
      let addresses = [];
      if (this.record.billingaddress) {
        this.record.billingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
        addresses.push(this.record.billingaddress);
      }
      if (this.record.shippingaddress) {
        this.record.shippingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png";
        addresses.push(this.record.shippingaddress);
      }
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "", country: "" };
      if (
        this.record.billname &&
        this.record.billname != this.record.billaddressee &&
        this.record.billname != this.record.billaddress
      )
        address.company = `${this.record.billname}, `;
      address.name = this.record.billaddressee || "";
      if (this.record.billaddress) address.address += this.record.billaddress;
      if (this.record.billaddress2)
        address.address += ` ${this.record.billaddress2}`;
      if (this.record.billcity) address.country += `${this.record.billcity}`;
      if (this.record.billzip) address.country += `, ${this.record.billzip}`;
      if (this.record.billcountry)
        address.country += `, ${
          this.record.billcountry.name || this.record.billcountry
        }`;
      return address;
    },

    shippingaddress() {
      let address = { name: "", address: "", country: "" };
      if (
        this.record.shipname &&
        this.record.shipname != this.record.shipaddressee &&
        this.record.shipname != this.record.shipaddress
      )
        address.company = `${this.record.shipname}, `;
      address.name = this.record.shipaddressee || "";
      if (this.record.shipaddress) address.address += this.record.shipaddress;
      if (this.record.shipaddress2)
        address.address += `, ${this.record.shipaddress2}`;
      if (this.record.shipcity) address.country += `${this.record.shipcity}`;
      if (this.record.shipzip) address.country += `, ${this.record.shipzip}`;
      if (this.record.shipcountry)
        address.country += `, ${
          this.record.shipcountry.name || this.record.shipcountry
        }`;
      return address;
    },
    overdue() {
      let overdue = false;
      if (this.record.duedate < new Date() && this.record.amountdue > 0)
        overdue = true;
      return overdue;
    },
    noprofit() {
      let noprofit = false;
      if (this.record.profit < 0) noprofit = true;
      this.record.transactionlines.forEach((line) => {
        if (line.profit < 0) noprofit = true;
      });
      return noprofit;
    },
  },
  components: {
    fiscalPrinter,
  },
};
</script>
